/* ==========================================================================

                        C O L O R S

========================================================================== */

// Colors Palette
$white: #FFFFFF !default;
$black: #000000 !default;

$alert-success-text: #fff !default;
$bata-red: #E60000 !default;
$bata-red-80: #E60000CC !default;
$bata-red-60: #E6000099 !default;
$bata-red-40: #E6000066 !default;
$bata-red-20: #E6000033 !default;
$black-warm: #140000 !default;
$blue: #0070d2 !default;
$breadcrumb-bg: transparent !default;
$green: #3AB795 !default;
$green1: #419500 !default;
$green2: #6d9f8e !default;
$keppel:#35A687 !default;
$light-blue: #7ed0ee !default;
$light-green: #9DDACA !default;
$light-orange: #FFD589 !default;
$light-warm-green: #96C573 !default;
$nav-tabs-active-link-hover-bg: transparent !default;
$orange1: #FFA400 !default;
$orange2: #ED850B !default;
$pink1: #FFC6BA !default;
$primary: #00a1e0 !default;
$red1: #E60000 !default;
$red: #D40001 !default;
$ruby-red: #851432 !default;
$turquoise: #00def3 !default;
$silverChalice:#adadad !default;
$slider-notActive : #DADADA !default;
$state-danger-text: #fff !default;
$storeBlack: #000000 !default;
$storeBlue: #0a8ae0 !default;
$storeGreen: #48c16d !default;
$storeLightBlue: #53ccc8 !default;
$storeLightGreen: #b3c745 !default;
$storeRed: #E60000 !default;
$storeViolet: #a357e3 !default;
$storeYellow: #dea200 !default;
$yellow1: #FFDA00 !default;
$warm-green: #419500 !default;
$white-warm: #FDFCFB !default;

$cold-grey-01: #F0F0F0 !default;
$cold-grey-02: #DEDEDE !default;
$cold-grey-03: #CBCBCB !default;
$cold-grey-04: #999A9A !default;
$cold-grey-05: #525459 !default;
$cold-grey-06: #27282A !default;

$warm-grey-01: #F2F2EE !default;
$warm-grey-02: #E7E6DD !default;
$warm-grey-03: #D3D1C7 !default;
$warm-grey-04: #ABA79B !default;
$warm-grey-05: #756E65 !default;
$warm-grey-06: #3A352E !default;

$grey1: #919496 !default;
$grey2: #CCCCCC !default;
$grey3: #E5E5E5 !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey9: #f9f9f9 !default;
$grey10: #eee !default;

$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;
$bg-overlay: rgba(0, 0, 0, 0.4) !default;
$bg-green-cold: rgba(150, 197, 115, 0.3) !default;
$bg-red: rgba(230, 0, 0, 0.1) !default;
$bg-ruby-red: rgba(133, 20, 50, 0.1) !default;

// Colors Symbols
$primary-color: $red1;
$success: $green1;
$danger: $red1;

$close-menu-bg: $grey10 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-border-grey: $grey4 !default;
$horizontal-rule-grey: $grey2 !default;
$hr-border-color: $grey2 !default;
$light-gray: $grey1 !default;
$link-color: $dark-gray !default;
$menu-link: $grey6 !default;
$product-number-grey: $grey2 !default;
$slightly-darker-gray: $grey4 !default;
$table-border-color: $grey2 !default;
$shoppingCart-freeShip-green:$warm-green;
$shoppingCart-freeShip-orange:$orange1;
$productInStock:$keppel;
$productNotInStock:$red1;
$productQtyOverStock:$red1; 
$search-breadcrumb:$grey1;

// Bootstrap overrides
$body-bg: $grey9 !default;
$card-cap-bg: $white !default;

$facebook-hover: #2863AD;
$google-plus-hover: #dd4d42;
$instagram-hover: #db2c78;
$linkedin-hover: #0076B2;
$pinterest-hover: #BA1621;
$twitter-hover: #1da1f2;
$youtube-hover: #e60000;
